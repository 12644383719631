import _swiper from 'swiper'
import $ from 'jquery'

$('document').ready(() => {
    const txMultiImagesSlideSelector = '.tx-multi-images-slide-wrapper'
    if( $(txMultiImagesSlideSelector).length ){
        
        const txMultiImagesSlide = new _swiper(txMultiImagesSlideSelector+' .tx-multi-images-slide', {
            navigation: {
                nextEl: `${txMultiImagesSlideSelector} .swiper-button-next`,
                prevEl: `${txMultiImagesSlideSelector} .swiper-button-prev`,
            },
            on: {
                slideChange: function(){
                    const activeIndex = this.activeIndex
                    $(`${txMultiImagesSlideSelector} .thumb-item`).removeClass('active')
                    $(`${txMultiImagesSlideSelector} .thumb-wrapper a[data-target="${activeIndex}"]`).closest('.thumb-item').addClass('active')
                }
            }
        })

        $(`${txMultiImagesSlideSelector} .thumb-wrapper a`).click(function(){
            const $el = $(this)
            const targetIndex = $el.attr('data-target')
            txMultiImagesSlide.slideTo(targetIndex)
        })
    }
})
