import $ from 'jquery';
$(document).ready(function ($) {
    $('#js-sticky-box__close').click(function(){
        $('#js-sticky-box').addClass('uk-hidden');
    });
});

$(window).scroll(function() {
    if ($(this).scrollTop() > 500) {
        $('.js-sticky-box').removeClass('box-hide');
    }else {
        $('.js-sticky-box').addClass('box-hide');
    }
    // if ($(this).scrollTop() > 3000) {
    //     $('.js-sticky-box').addClass('box-hide');
    // }
});