// import external dependencies
import 'jquery';

import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
// loads the Icon plugin

import './../../../../assets/js/core';

UIkit.use(Icons);

// import local dependencies
import Router     from './../../../../assets/js/util/Router';
import common     from './routes/common';
import home       from './routes/home';
import aboutUs    from './routes/about';
import tour       from './routes/tour';

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Home page
  home,
  // About Us page, note the change from about-us to aboutUs.
  aboutUs,
  tour,
});

// Load Events
jQuery(document).ready(() => routes.loadEvents());

// Import everything from components
import './components/menu.js'; import './components/sticky-box.js'; import './components/testimonial-slide.js';

import './_import-components';